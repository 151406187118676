html {   
    height: 100%;   
}  
body {   
    height: 100%;   
}  
.global-container {  
    height: 100%;  
    display: flex;  
    align-items: center;  
    justify-content: center;  
    /* background-color: #f5f5f5;   */
}  
.login-form form {  
    padding-top: 10px;  
    font-size: 14px;  
    margin-top: 30px;  
}  
/* .card-title {   
font-weight: 300;  
 }   */
.login-form button .btn {  
    font-size: 14px;  
    margin-top: 20px;  
}
.login-form {   
    width: 330px;  
    margin: 20px;  
}  
.sign-up {  
    text-align: center;  
    padding: 20px 0 0;  
}  
.alert {  
    margin-bottom: -30px;  
    font-size: 13px;  
    margin-top: 20px;  
}    
.login-form { 
    background-color: #f5f5f5;  
    width: 39%;
}

.login-form button {
    background: #26a69a;
    border: #26a69a;
    font-size: 20px;
    color: white;
}

.login-form button:hover {
    background: #26a69a;
    color: white;
}